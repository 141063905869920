<template>
  <button fi-select-button @click="$emit('click')">
    <label >
      {{ label }}
    </label>
  </button>

</template>

<script>
export default {
  name: 'FiSelectButton',
  props: {
    label: String,
  },
};
</script>

<style lang="less">
@import '~@/less/proj.less';
[fi-select-button] {.p(24); .flex(); .justify-start(); .items-center(); .w(100%); border: 2px solid #333; .bgc(#262626); .br(8); .pointer();
  > label {.c(#fff); .fs(16); .pointer(); }
  &:hover {border: 2px solid #fff;}
}
</style>